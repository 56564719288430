import React from 'react'
import { handleSocialRedirect } from '../utils'
import fb from '../Assets/Img/fb.svg'
import linkedin from '../Assets/Img/linkedin.svg'
import x from '../Assets/Img/x.svg'
import instagram from '../Assets/Img/instagram.svg'
import ming from '../Assets/Img/ming.svg'
import youtube from '../Assets/Img/youtube.svg'
import tiktok from '../Assets/Img/tiktok.svg'
import { handleUTMLink } from '../utils'

const SocialFooter = () => {
    return (
        <div className='footer-copyright__wrapper w-100'>
            <div className='d-none d-md-flex align-items-center justify-content-between w-100'>
                <div className='d-flex align-items-center'>
                    <p className='footer-sub-text pe-3'>Terms & Conditions</p>
                    <p className='footer-sub-text cursor-pointer' onClick={() => {
                        handleUTMLink('https://www.freeprivacypolicy.com/live/66d190e8-61be-4620-bd87-e8333717c71a')
                    }}>Privacy Policy</p>
                </div>
                <div>
                    <p className='footer-sub-text'>Genti Media 2023. All rights reserved</p>
                </div>
                <div className='d-md-none d-xl-block'>
                    <p className='footer-sub-text mb-1'>Follow us</p>
                    <div className='d-flex align-items-center'>
                        <div className='pe-2' role='button' onClick={() => {
                            handleSocialRedirect('facebook')
                        }} >
                            <img src={fb} alt='social' />
                        </div>
                        <div className='pe-2' role='button' onClick={() => {
                            handleSocialRedirect('linkedin')
                        }}>
                            <img src={linkedin} alt='social' />
                        </div>
                        <div className='pe-2' role='button' onClick={() => {
                            handleSocialRedirect('twitter')
                        }}>
                            <img src={x} alt='social' />
                        </div>
                        <div className='pe-2' role='button' onClick={() => {
                            handleSocialRedirect('instagram')
                        }}>
                            <img src={instagram} alt='social' />
                        </div>
                        <div className='pe-2' role='button' onClick={() => {
                            handleSocialRedirect('medium')
                        }}>
                            <img src={ming} alt='social' />
                        </div>
                        <div className='pe-2' role='button' onClick={() => {
                            handleSocialRedirect('youtube')
                        }}>
                            <img src={youtube} alt='social' />
                        </div>
                        <div className='' role='button' onClick={() => {
                            handleSocialRedirect('tiktok')
                        }}>
                            <img src={tiktok} alt='social' />
                        </div>

                    </div>
                </div>
            </div>
            <div className='d-flex flex-column d-md-none align-items-center justify-content-center w-100 px-3'>
                <div className='mb-3 w-100'>
                    <p className='footer-sub-text mb-1 text-center mb-2'>Follow us</p>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className='' role='button' onClick={() => {
                            handleSocialRedirect('facebook')
                        }}>
                            <img src={fb} alt='social' />
                        </div>
                        <div className='' role='button' onClick={() => {
                            handleSocialRedirect('linkedin')
                        }}>
                            <img src={linkedin} alt='social' />
                        </div>
                        <div className='' role='button' onClick={() => {
                            handleSocialRedirect('twitter')
                        }}>
                            <img src={x} alt='social' />
                        </div>
                        <div className='' role='button' onClick={() => {
                            handleSocialRedirect('instagram')
                        }}>
                            <img src={instagram} alt='social' />
                        </div>
                        <div className='' role='button' onClick={() => {
                            handleSocialRedirect('medium')
                        }}>
                            <img src={ming} alt='social' />
                        </div>
                        <div className='' role='button' onClick={() => {
                            handleSocialRedirect('youtube')
                        }}>
                            <img src={youtube} alt='social' />
                        </div>
                        <div className='' role='button' onClick={() => {
                            handleSocialRedirect('tiktok')
                        }}>
                            <img src={tiktok} alt='social' />
                        </div>

                    </div>
                </div>
                <div className='d-flex align-items-center mb-3 justify-content-between w-100'>
                    <p className='footer-sub-text '>Terms & Conditions</p>
                    <p className='footer-sub-text'>Privacy Policy</p>
                </div>
                <div>
                    <p className='footer-sub-text'>Genti Media 2023. All rights reserved</p>
                </div>

            </div>
        </div>
    )
}

export default SocialFooter