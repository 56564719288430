export const faqs = [
    {
        question: "How do I become a Voice Over Artist on Genti Media?",
        answer: "To be a voice-over artist on Genti Media, kindly fill out the form below: <a href='https://docs.google.com/forms/d/e/1FAIpQLSdSDvmMP3Nib8GLBpcl05BmKzc8dKlIj78KfLQalMadDiQjJQ/viewform' target='__blank' class='faq-link cursor-pointer'>Voice Over Artist Application Form"
    },
    {
        question: "How do I become a writer for Genti Media?",
        answer: "You can do so through <a href='https://creator.gentimedia.com' class='faq-link'>creator.gentimedia.com</a>. Kindly send an email to our Content and Community Associate, Angela, at angela@gentimedia.com if you have any issues or further questions regarding the onboarding process. <br/> <p class='mt-3'>Below is a video of the Creator Portal Walkthrough to aid the process: <a href='https://youtu.be/ID4sJ_vx-G4' class='faq-link'>Creator Portal Walkthrough</a></p>"
    }, {
        question: "How do I become a creator for Genti Media?",
        answer: "You can do so through <a href='https://creator.gentimedia.com' class='faq-link'>creator.gentimedia.com</a>. Kindly send an email to our Content and Community Associate, Angela, at angela@gentimedia.com if you have any issues or further questions regarding the onboarding process. <br/> <p class='mt-3'>Below is a video of the Creator Portal Walkthrough to aid the process: <a href='https://youtu.be/ID4sJ_vx-G4' class='faq-link'>Creator Portal Walkthrough</a></p>"

    }, {
        question: "How do I get a coin?",
        answer: "To get coins, navigate to the My Coins tab on the app. You will see various options. When you pick the most convenient one, it will lead you to different methods of payment, allowing you to choose the one that is most convenient for you."
    }, {
        question: "I bought coins and they have not reflected on my dashboard.",
        answer: "We apologize for the inconvenience encountered with the purchase of coins on the Genti Media Platform. Kindly reach out to us on WhatsApp through the chat link, sending your evidence of payment and email used alongside."
    },
    {
        question: "How do I send my story to Genti Media?",
        answer: "You can do so through <a href='https://creator.gentimedia.com' class='faq-link'>creator.gentimedia.com</a>. Kindly send an email to our Content and Community Associate, Angela, at angela@gentimedia.com if you have any issues or further questions regarding the onboarding process. <br/> <p class='mt-3'>Below is a video of the Creator Portal Walkthrough to aid the process: <a href='https://youtu.be/ID4sJ_vx-G4' class='faq-link'>Creator Portal Walkthrough</a></p>"

    },
    
    {
        question: "How can I listen offline?",
        answer: "To access the content offline, you would need to unlock the content through the purchase of coins, after which you can download the content of your choice."
    }, {
        question: "I forgot my password, what can I do?",
        answer: "You can click on forgot password on the app and out in your email to reset your password"
    },

]