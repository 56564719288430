import React from 'react'
import language from '../Assets/Img/new_language.svg'
import { Row, Col } from 'reactstrap'
import { Slide } from 'react-awesome-reveal'
import { handleUTMLink } from '../utils'
import { isMobile } from '../utils'
import languageMobile from '../Assets/Img/language.png'

// import mobile_language from '../Assets/Img/mobile_languages.svg'

const Languages = () => {
    return (

        <section className='language-section section-container'>

            <Row className='d-flex align-items-center genti-container-fluid mb-0 gy-3'>

                <Col sm='12' md='6' className='order-2 order-md-1'>
                    <div className=' section-text_wrapper flex flex-col flex-end'>
                        <h3 className='section-main_text mb-0'>Our Languages</h3>
                        <p className='section-sub_text'>Listen to stories in Igbo, Yoruba, Hausa, Pidgin, and more Nigerian & African languages.</p>
                        <div className='section-btn__wrapper'>
                            <button className='btn section-btn genti-gradient_btn' onClick={handleUTMLink}>Get Started</button>

                        </div>
                    </div>
                </Col>
                <Col sm='12' md='6' className='order-1 order-md-2'>
                    <Slide direction='right' fraction={1} triggerOnce>
                        <div className='pe-md-5 pe-0 section-img_wrapper'>
                            <img src={isMobile ? languageMobile : language} alt='genti-img' />
                        </div>
                    </Slide>

                </Col>


            </Row>

        </section>
    )
}

export default Languages