import React from 'react'
import offline from '../Assets/Img/new_offline.svg'
import offlineMobile from '../Assets/Img/offline.png'
// import MobileOffline from '../Assets/Img/nobile_offline2.svg'
import { Row, Col } from 'reactstrap'
import { Slide } from "react-awesome-reveal";
import { handleUTMLink } from '../utils';
import { isMobile } from '../utils';

const Offline = () => {
    return (
        <section className=' Offline-section section-container p'>

            <Row className='d-flex  genti-container-fluid mb-0 gx-0 gx-md-2 align-items-center'>
                <Col sm='12' md='6'>
                    <Slide direction='left' fraction={1} triggerOnce>
                        <div className='pe-md-5 pe-0 section-img_wrapper'>
                            <img src={isMobile ? offlineMobile : offline} alt='genti-img' />
                        </div>
                    </Slide>

                </Col>
                <Col sm='12' md='6'>
                    <div className=' section-text_wrapper'>
                        <h3 className='section-main_text mb-0 offline-text d-block d-md-none'>No Data? No Problem</h3>
                        <h3 className='section-main_text mb-0 offline-text d-none d-md-block'>No Data?</h3>
                        <h3 className='section-main_text mb-0 offline-text d-none d-md-block'>No Problem</h3>

                        <p className='section-sub_text px-4 px-md-0'>Get started on one of our premium plans to listen to unlimited stories offline & data-free</p>
                        <div className='section-btn__wrapper'>
                            <button className='btn section-btn genti-gradient_btn' onClick={handleUTMLink}>Get Started</button>

                        </div>
                    </div>
                </Col>


            </Row>
        </section>
    )
}

export default Offline