import React from 'react'
import Header from './Header'
import heroImg from '../../Assets/Img/new_heroimg.svg'
import play from '../../Assets/Img/play.svg'
import mobilePlay from '../../Assets/Img/mobile_play.svg'
import { Row, Col } from 'reactstrap'
import { Zoom } from 'react-awesome-reveal'
import { handleUTMLink } from '../../utils'
import { isMobile } from '../../utils'
import mobileHero from '../../Assets/Img/hero.png'

const Hero = () => {

  return (
    <div className='hero-wrapper'>
      <Header />
      <div className=''>
        <Row>
          <Col sm='12' md='6'>
            <div className='hero-text_wrapper d-flex align-items-end h-100 pb-3'>
              <div className='px-3 px-md-5 align-self-end mb-5  mb-xl-3'>
                <h3 className='hero-main_text ' data-text='African Stories On The Go!'>
                  African Stories On The G<span className='ps-1'><img src={isMobile ? mobilePlay : play} alt='icon' /></span>!
                </h3>
                <p className='hero-sub_text'>
                  400,000+ minutes of compelling African stories, podcasts, sermons & more. Listen for free
                </p>
                <div className='hero-cta_container cursor-pointer' onClick={handleUTMLink} />
              </div>
            </div>

          </Col>
          <Col sm='12' md='6'>
            <Zoom fraction={isMobile ? 0 : 1} triggerOnce>
              <div className='px-2 px-md-3 hero-img__wrapper'>
                <div class="gradient" />
                <img src={isMobile?mobileHero:heroImg} alt='genti_player' />
              </div>
            </Zoom>

          </Col>
        </Row>



      </div>
    </div>
  )
}

export default Hero