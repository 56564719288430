import React from 'react'
import { Row, Col } from 'reactstrap'
import logo from '../Assets/Img/logo.svg'
import googlePlay from '../Assets/Img/google_play.svg'
import appleStore from '../Assets/Img/apple_store.svg'
import whatsapp from '../Assets/Img/whatsapp.svg'
import fb from '../Assets/Img/fb.svg'
import linkedin from '../Assets/Img/linkedin.svg'
import x from '../Assets/Img/x.svg'
import instagram from '../Assets/Img/instagram.svg'
import ming from '../Assets/Img/ming.svg'
import youtube from '../Assets/Img/youtube.svg'
import tiktok from '../Assets/Img/tiktok.svg'
import mastercard from '../Assets/Img/mastercard.png'
import cchub from '../Assets/Img/Cchub.png'

import { handleUTMLink, handleWhatsappChat } from '../utils'
import { useScroll } from './ScrollProvider'
import { handleSocialRedirect } from '../utils'
import About from './About'
import SocialFooter from './SocialFooter'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate=useNavigate()
    const { hideFooterLogo } = useScroll();
    return (
        <footer className='footer-section section-container'>
            <div className='genti-container-fluid'>
                <Row className=' mb-5 gy-5 gx-md-5'>
                    <Col sm='12' md='12' xl='12'>
                        <div>


                            {/* <p className='footer-text'>
                                African Audio Stories, Audiobooks, Podcasts and more, in your pocket and on the go. Download the app now and start listening!
                            </p> */}
                            <About />
                            <div className='my-5 py-2  gap- align-items-center '>
                                <h3 className=' text-white py-2 text-center'>Backed by </h3>
                                <div className='d-flex align-items-center gap-2 gap-md-5 justify-content-center '>


                                    <a
                                        href='https://cchub.africa/co-creation-hub-nigeria-announces-second-cohort-of-12-new-start-ups-selected-for-the-mastercard-foundation-edtech-fellowship/'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className='cursor-pointer w-100 d-md-flex justify-content-end text-left '>
                                        <img src={mastercard} alt='mastercard_foundation' className='partners_logo mastercard' />
                                    </a>

                                    <div className='logo_separator  mx-md-5 d-none d-md-block' />

                                    <a
                                        href='https://cchub.africa/co-creation-hub-nigeria-announces-second-cohort-of-12-new-start-ups-selected-for-the-mastercard-foundation-edtech-fellowship/'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className='cursor-pointer w-100 d-flex justify-content-start'>
                                        <img src={cchub} alt='cchub' className='partners_logo cchub' />
                                    </a>



                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='btn download-btn d-flex align-items-center me-2' onClick={handleUTMLink}>
                                    <div className='me-2' >
                                        <img src={googlePlay} alt='icon' />
                                    </div>
                                    <div>
                                        <p className='download-text mb-0'>GET IT ON</p>
                                        <h3 className='download-store mb-0'>Google Play</h3>
                                    </div>
                                </div>
                                <div className='btn download-btn d-flex align-items-center' onClick={handleUTMLink}>
                                    <div className='me-2'>
                                        <img src={appleStore} alt='icon' />
                                    </div>
                                    <div>
                                        <p className='download-text mb-0'>Download on the</p>
                                        <h3 className='download-store mb-0'>App Store</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-none d-md-block d-xl-none mt-4'>
                            <p className='footer-sub-text mb-1'>Follow us</p>
                            <div className='d-flex align-items-center'>
                                <div className='pe-2' role='button'>
                                    <img src={fb} alt='social' />
                                </div>
                                <div className='pe-2' role='button'>
                                    <img src={linkedin} alt='social' />
                                </div><div className='pe-2' role='button'>
                                    <img src={x} alt='social' />
                                </div><div className='pe-2' role='button'>
                                    <img src={instagram} alt='social' />
                                </div><div className='pe-2' role='button'>
                                    <img src={ming} alt='social' />
                                </div><div className='pe-2' role='button'>
                                    <img src={youtube} alt='social' />
                                </div><div className='' role='button'>
                                    <img src={tiktok} alt='social' />
                                </div>

                            </div>
                        </div>
                    </Col>
                    {/* <Col sm='12' md='6' xl='7'> */}
                    <Col sm='12' md='12' xl='12'>

                        <div className='ms-xl-4 ms-0'>
                            <Row className='gx-md-5 gx-1 gy-5'>
                                <Col xs='6' xl='4'>
                                    <div className=''>
                                        <h3 className='footer-header_text'>Explore</h3>
                                        <ul className='footer-link__wrapper'>
                                            <li onClick={() => handleUTMLink('https://gentiaudio.app.link')}>Start Listening</li>

                                            <li onClick={() => navigate('/how-coins-work')}>How Coins work</li>
                                            {/* <li>Subscriptions</li> */}
                                            <li>Blog</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs='6' xl='4'>
                                    <div className=''>
                                        <h3 className='footer-header_text'>For Storytellers</h3>
                                        <ul className='footer-link__wrapper'>
                                            <li onClick={() => handleUTMLink('https://creator.gentimedia.com')}>Creator Portal</li>
                                            <li onClick={() => handleUTMLink('https://genti-dev.s3.us-east-2.amazonaws.com/Genti+Content+Owner+Offerings+For+Existing+Audio+Work.pdf')}>Creator Offering</li>
                                            <li onClick={() => handleUTMLink(' https://docs.google.com/forms/d/e/1FAIpQLSdSDvmMP3Nib8GLBpcl05BmKzc8dKlIj78KfLQalMadDiQjJQ/viewform?usp=sf_link')}>Voice Artists</li>
                                            <li >Writers</li>

                                        </ul>
                                    </div>
                                </Col>
                                <Col xs='6' xl='4'>
                                    <div className=''>
                                        <h3 className='footer-header_text'>Need Help?</h3>
                                        <ul className='footer-link__wrapper'>
                                            <li onClick={() => navigate('/faqs')}>FAQs</li>
                                            <li>
                                                <a href="mailto:support@gentimedia.com">Email Us</a>
                                            </li>
                                        </ul>
                                        <div className=''>
                                            {/* <h3 className='footer-header_text' >Chat with us</h3> */}
                                            <div className='footer-contact' role='button' onClick={handleWhatsappChat}>
                                                <img src={whatsapp} alt='whatsapp' />
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col xs='6' xl='3'>
                                    {/* <div className=''>
                                        <h3 className='footer-header_text' >Chat with us</h3>
                                        <div className='footer-contact' role='button' onClick={handleWhatsappChat}>
                                            <img src={whatsapp} alt='whatsapp' />
                                        </div>
                                    </div> */}
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row >
                <SocialFooter />
            </div >

        </footer >

    )
}

export default Footer